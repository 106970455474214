.instructions-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.instructions-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.instructions-play-button {
    display: block;
    margin: 10px auto;
    margin-bottom: 0px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: var(--chain-primary);
    color: white;
    width: 100%;
    transition: background-color 0.3s;
}

.instructions-header-title {
    color: var(--cluess-primary);
    font-size: 2em;
}

.instructions-play-button.clueless {
    background: var(--cluess-primary);
}

.instructions-play-button.crossword {
    background: var(--crosswords-dark);
}

.instructions-play-button.waterfall {
    background: var(--waterfall-dark);
}

.instructions-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: black
}

.instructions-chain-example-word {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    gap: 2px;
}

.instructions-chain-example-letter {
    background: var(--chain-primary);
    color: white;
    padding: 5px;
}

.instructions-chain-example {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.instructions-chain-arrow-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

.chain-arrow-btn-instructions {
    width: 20px;
    height: 20px;
}

.instructions-chain-example-board {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.instructions-chain-example-explanation-word {
    color: var(--chain-primary);
}

.instructions-chain-example-explanation-line {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.chain-arrow-example-instructions {
    width: 20px;
    height: 20px;
}

.instructions-chain-example-explanations {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding-right: 0px;
}

.instructions-header-how-to-play {
    color: var(--chain-primary);
    margin-bottom: 0px;
    margin-top: 0px;
}

.instructions-header-how-to-play.clueless {
    color: var(--dark-text);
    font-size: 1.2em;
}

.instructions-header-how-to-play.waterfall {
    color: var(--waterfall-dark);
}

.instructions-header-title.crossword {
    color: var(--crosswords-dark);
}

.instructions-category-example-container {
    display: flex;
    flex-direction: row;
    gap: .5em;
    margin-bottom: .4em;
}

.instructions-chain-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.instructions-waterfall-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.instructions-waterfall-example-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.instructions-waterfall-example-letter {
    background: var(--chain-primary);
    color: white;
    padding: 5px;
}

.instructions-waterfall-example-letter-disabled {
    background-color: var(--waterfall-grey);
    color: black;
    padding: 5px;
}

.instructions-waterfall-example-letter-selected {
    background: var(--chain-third);
    color: white;
    padding: 5px;
}

.instructions-waterfall-example-letter-empty {
    width: 20px;
    height: 1px;
}

.instructions-waterfall-example-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.instructions-waterfall-example-divider-line {
    display: flex;
    width: 120px;
    height: 3px;
    background: var(--waterfall-dark);
}

.instructions-waterfall-example-word-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.instructions-waterfall-example-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.instructions-waterfall-example-word-letter {
    background: var(--chain-third);
    color: white;
    padding: 5px;
}

.waterfall-arrow-example-instructions {
    transform: rotate(90deg);
    height: 25px;
    width: auto;
}

.instructions-waterfall-example-text {
    padding-top: 15px;
}

.instructions-clueless-example {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.instructions-clueless-example-category {
    background: var(--cluess-primary);
    color: white;
    padding: 5px;
}

.instructions-clueless-category-example-row {
    display: flex;
    flex-direction: column;
    gap: .2em;
}

.instructions-clueless-example-text {
    font-size: .8em;
}

.instructions-clueless-clue-example {
    display: flex;
    flex-direction: row;
}

.instructions-clueless-clue {
    margin: 2px 0; /* Adjust margin to control spacing between clues */
    background-color: white;
    border: 2px solid var(--cluess-primary);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: var(--dark-text);
    width: 25em;
    box-sizing: border-box;
    min-height: 30px;
    padding: 0;
    height: 1em;
}

.instructions-clueless-clue-number {
    background-color: var(--cluess-primary);
    color: white;
    height: 100%;
    width: 80px;
    flex: 0 0 auto;
    font-size: 16px;
    align-content: center;
    text-align: center;
    border-radius: 8px;
}

.instructions-clueless-clue-text-hidden {
    visibility: hidden;
}

.instructions-clueless-clue-text {
    flex-grow: 1;
    padding: 3px 20px;
    font-size: 16px;
    margin: 0px;
    text-align: left;
}

.instructions-clueless-clue-time {
    font-size: .7em;
    flex: 0 0 auto;
    text-align: center;
    padding: 5px 10px;
}

@media (max-width: 1100px) {
    .instructions-content {
        max-width: 90%;
        padding: 1em;
    }

    .instructions-clueless-clue {
        width: 20em;
    }
}