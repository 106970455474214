/* src/css/general/TitleComponents.css */
.game-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: .5em;
    gap: 2em;
}

.game-title {
    color: var(--dark-text);
    font-size: 1.8em;
    margin: .5em 0 0;
}

.game-title.waterfall {
    color: var(--waterfall-dark);
}

.game-title.chain {
    color: var(--chain-primary);
}

.game-title.clueless {
    color: var(--cluess-primary);
}

.game-title.crossword {
    color: var(--crosswords-primary);
}

.game-subtitle {
    color: var(--secondary-text);
    padding: 0;
    font-size: 1em;
    margin: 0;
}

@media (max-width: 1100px) {
    .game-title-container {
        display: none;
    }
}