/* Add this CSS to your existing stylesheet */

/* General print styles */
@media print {
    .crosswords-buttons-container {
        display: none; /* Hide the buttons */
    }

    .grid {
        width: 100%; /* Expand the grid to full width */
        max-height: 100vh;
        grid-template-rows: repeat(20, 3.5vw);
    }

    .row {
        display: grid;
        grid-template-columns: repeat(20, 3.5vw); /* Adjust size as needed for print */
    }

    .square {
        width: 3.5vw;
        height: 3.5vw;
        border: 1px solid #000;
    }

    .square.black {
        background-color: var(--black);
    }

    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;                 /* Firefox 48 – 96 */
        print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
    }

    .square-text {
        display: none;
    }

    .square .square.secondary, .square.blue, .square.yellow, .square.green, .square.red {
        background-color: #FFF;
    }

    .hints-and-buttons-container {
        width: 100%;
        margin-top: 20px;
    }

    .hint {
        font-size: 2vw;
    }

    .hints-section .highlight {
        background-color: #FFF;
    }

    .hint-section-header {
        font-size: 3vw;
    }

    .header {
        background: #FFF;
        margin-bottom: 10px;
        box-shadow: none;
    }

    .hamburger-menu {
        display: none;
    }

    .printing-answers-container {
        display: flex;
        width: 100%;
        -webkit-transform:rotate(180deg);
        -moz-transform:rotate(180deg);
        -o-transform:rotate(180deg);
        -ms-transform:rotate(180deg);
        transform:rotate(180deg);
    }
}
