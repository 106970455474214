/* src/css/general/StatsAndInfo.css */
.stats-and-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: .5em 0 .5em;
    gap: 8em;
    padding: 0;
}

.stats-and-info-container p {
    margin: 0;
    font-size: 1.12em;
    align-content: center;
    color: var(--secondary-text);
}

.stats-and-info-container p.tiles-used {
    color: var(--waterfall-tiles-used);
}

.generic-game-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5em;
}

.game-info-clock-icon {
    font-size: 1.2em;
    color: var(--secondary-text);
}

.share-icon {
    font-size: 1.4em;
    padding-bottom: .1em;
    color: var(--secondary-text);
}

.game-info-and-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .2em;
    justify-content: center;
}

.category-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .7em;
}

.plays-and-ratings-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: .2em;
}

.plays-and-ratings-container p {
    font-size: .8em;
    margin: 0;
}

.category-arrow {
    height: 1.5em;
    width: auto;
    cursor: pointer;
}

.category-arrow.disabled-arrow {
    opacity: 0.5;
    cursor: not-allowed;
}

.category-heading {
    font-size: 1em;
    margin: 0;
}

@media (max-width: 1100px) {
    .stats-and-info-container.clueless, .stats-and-info-container.crossword {
        align-self: center;
        justify-content: space-between;
        width: 96%;
        gap: 0;
    }

    .category-heading {
        font-size: .9em
    }

    .stats-and-info-container p {
        font-size: .9em;
    }

    .category-arrow {
        height: 1.4em;
    }
}