.oops-page-container {
    position: fixed;
    display: flex;
    top: 3.6em;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    gap: .5em;
}

.oops-page-text {
    margin: 0;
    padding: 0;
    color: var(--dark-text);
}

.oops-page-image {
    align-self: center;
    width: 5em;
    height: auto;
    margin: 0;
    padding: 0;
    margin-top: -3em;
}

.oops-page-retry {
    background: var(--primary-purple);
    color: var(--white);
    border: 3px solid var(--primary-purple);
    border-radius: 20px;
    font-size: 1.2em;
    width: 10vw;
}