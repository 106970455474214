/* src/css/Header.css */
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400');

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 55px;
    background-color: #f8f9fa;
    margin-bottom: 2px; /* for box shadow */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.header-home-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.hamburger-menu {
    cursor: pointer;
    margin-right: 20px;
    margin-left: 20px;
}

.hamburger-icon {
    width: 30px;
    height: 30px;
}

.question-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.header-left-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-brain-games-icon {
    width: auto;
    height: 30px;
    margin-top: -5px;
    padding-right: 10px;
}

.header-title {
    font-size: 32px;
    letter-spacing: 1px;
    margin: 0;
    color: #1b1b1b;
    font-family: 'teko', sans-serif;
}

.header-game-name {
    font-size: 2em;
    color: #1b1b1b;
    font-family: 'teko', sans-serif;
    position: absolute;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    margin-top: .2em;
}

.header-game-name.chain {
    color: var(--chain-primary);
}

.header-game-name.waterfall {
    color: var(--waterfall-dark);
}

.header-game-name.crossword {
    color: var(--crosswords-dark);
}

.header-game-name.clueless {
    color: var(--cluess-primary);
}

.header-menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 30%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 5000;
    margin: 0;
    padding: 0;
}

.header-menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
    margin: 0;
    padding: 0;
}

.header-menu-link {
    padding-top: .5em;
    padding-bottom: .5em;
}

.header-menu a {
    display: block;
    text-decoration: none;
    color: black;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
}

.header-menu a:hover {
    background-color: #f0f0f0;
}

.header-menu a:last-child {
    border-bottom: none;
}

.hamburger-menu-game-icon {
    height: 2em;
    align-self: center;
}

.header-game-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1em;
    width: 100%;
    margin: 0;
    padding: 0;
}

.hamburger-menu-game-icon-container {
    display: flex;
    padding: .2em;
    align-items: center;
    justify-content: center;
    border-radius: .4em;
    margin-left: 1em;
    width: 2em;
}

.hamburger-menu-game-icon-container.chain, .popup-game-icon-container.chain {
    background: var(--chain-primary);
}

.hamburger-menu-game-icon-container.waterfall, .popup-game-icon-container.waterfall {
    background: var(--waterfall-primary);
}

.hamburger-menu-game-icon-container.clueless, .popup-game-icon-container.clueless {
    background: var(--clueless-music-primary);
}

.hamburger-menu-game-icon-container.crossword, .popup-game-icon-container.crossword {
    background: var(--crosswords-primary);
}

.header-game-icon-text {
    font-weight: 600;
}
@media screen and (max-width: 1100px) {
    .header-menu {
        width: 50%;
    }

    .header-title {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .header-menu {
        width: 100%;
    }
}

.popup-game-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: .5em;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: .5em;
}

.popup-game-container.crossword {
    border: 2px solid var(--crosswords-primary);
}

.popup-game-container.waterfall {
    border: 2px solid var(--waterfall-primary);
}

.popup-game-container.chain {
    border: 2px solid var(--chain-primary);
}

.popup-game-container.clueless {
    border: 2px solid var(--clueless-music-primary);
}

.popup-game-icon-container {
    display: flex;
    padding: .2em;
    padding-right: .3em;
    align-items: center;
    justify-content: center;
    width: 3.5em;
}

.popup-game-icon-text {
    font-weight: 600;
}

.popup-game-icon {
    height: 3em;
    align-self: center;
}

.popup-more-games-container {
    display: flex;
    flex-direction: column;
    gap: .5em;
    margin-top: 1.5em;
}

.popup-game-link {
    text-decoration: none;
    color: black;
}