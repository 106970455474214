.waterfall-grid {
    display: grid;
    grid-template-rows: repeat(7, 50px);
    gap: 2px;
    margin: 5px 0;
    position: relative;
}

.waterfall-row {
    display: flex;
    gap: 5px;
}

.waterfall-row.bottom .waterfall-tile {
    background-color: var(--primary);
}

.waterfall-row.bottom .waterfall-tile.selected {
    background-color: var(--light);
}

.waterfall-row:not(.bottom) {
    margin-bottom: 30px;
}

.waterfall-row.bottom {
    margin-top: 10px;
    position: relative;
}

.waterfall-row.bottom::before {
    content: '';
    position: absolute;
    top: -9px; /* Adjusted to bring the blue bar closer */
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--waterfall-primary);
}

.waterfall-tile {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--waterfall-grey);
    color: black;
    border: 1px solid var(--waterfall-grey);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.waterfall-tile.bottom {
    background-color: white;
}

.waterfall-tile.empty {
    visibility: hidden;
}

.waterfall-tile.selected {
    background-color: #ffa500;
    transform: scale(1.1);
}

.waterfall-word-input {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.current-word {
    font-size: 26px;
    color: var(--waterfall-dark);
    font-weight: bold;
    min-height: 1em; /* Ensure space is always reserved for the word */
    text-align: center;
}

@media (max-width: 1100px) {
    .waterfall-word-input {
        width: 90%;
        max-width: 25em;
    }

    .current-word {
        margin: 15px;
    }

    .waterfall-grid {
        grid-template-rows: repeat(7, 50px);
        gap: 2px;
        margin: 5px 0;
        position: relative;
    }
}

.tiles-used {
    color: var(--light);
}

.words-left {
    color: grey; /* Grey color for the words left text */
}