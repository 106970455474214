/* src/css/clueless/Clueless.css */
.clueless-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.clueless-guess-inputs {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
    flex-wrap: wrap;
}

.clueless-guess-tile {
    width: 40px;
    height: 40px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: black;
    border: 2px solid var(--cluess-primary);
    border-radius: 4px;
    background: white;
    transition: background-color 0.3s, border-color 0.3s;
}

.clueless-guess-tile.active {
    background: var(--cluess-button-disabled);
}

.hidden {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.clueless-clue {
    margin: 2px 0; /* Adjust margin to control spacing between clues */
    background-color: white;
    border: 2px solid var(--cluess-primary);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: var(--dark-text);
    width: 100%;
    max-width: 60em;
    box-sizing: border-box;
    padding: 0; /* Remove any padding */
}

.clueless-clue-number {
    background-color: var(--cluess-primary);
    color: white;
    height: 100%;
    width: 80px;
    flex: 0 0 auto;
    font-size: 16px;
    align-content: center;
    text-align: center;
    border-radius: 8px;
}

.clueless-clue-text {
    flex-grow: 1;
    padding: 3px 20px;
    font-size: 16px;
    margin: 0px; /* Remove margin */
    visibility: hidden; /* Hide the clue text by default */
    height: 0;
    width: 0;
    text-align: left;
}

.clueless-clue.visible .clueless-clue-text {
    visibility: visible; /* Show the clue text when visible */
    height: auto;
}

.clueless-clue-time {
    font-size: 16px;
    flex: 0 0 auto;
    text-align: center;
    padding: 5px 10px;
}

.clueless-guess-tile.active {
    border-color: var(--cluess-primary);
}

.clueless-guess-tile.flash {
    animation: flash-animation 0.5s ease-in-out infinite alternate;
}

@media (max-width: 1100px) {
    .clueless-guess-inputs {
        margin: 0;
        padding: 0;
        background: white;
        border-top-style: solid;
        border-color: #ccc;
    }

    .clueless-guess-tile {
        width: 1em;
        height: 1em;
        padding: .3em;
        font-size: 1em;
    }
}

@keyframes flash-animation {
    0% {
        background-color: var(--cluess-primary);
        color: white;
    }
    100% {
        background-color: white;
        color: black;
    }
}