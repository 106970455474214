@import './ColorConstants.css';

.home-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 96%;
    margin-left: 2%;
    margin-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    align-items: center;
}

.home-content .legal-info-item-container {
    display: flex;
    position: absolute;
    bottom: .4em;
    left: 1em;
    padding: 0;
    font-size: .8em;
    gap: .2em;
    margin-top: 0px;
}

.home-content .legal-info-item-container p, .home-content .legal-info-item-container a {
    margin: .4em 0 0;
    padding: 0;

}

.home-text {
    width: 38%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
}

.home-title {
    font-size: 2.5em;
    color: var(--black);
    margin-bottom: 0px;
}

.home-description {
    font-size: 1.2em;
    color: var(--black);
    margin-top: 0px;
    margin-bottom: 0px;
}

.home-games {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.home-tile {
    background-color: var(--white);
    color: var(--dark);
    font-size: 30px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid grey;
}

.home-main-icon {
    height: auto;
    width: 400px;
}

.home-tile-icon {
    height: 110px;
}

.home-tile-title {
    font-size: 24px;
    color: var(--black);
    margin: 0px;
    text-decoration: none;
    text-align: left;
}

.home-tile-button {
    text-decoration: none;
}

.home-tile-description {
    color: var(--black);
    font-size: 14px;
    margin: 0px;
    text-decoration: none;
    text-align: left;
}

.home-tile-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
}

.home-tile-header.crossword {
    background-color: var(--crosswords-primary);
}

.home-tile-header.clueless {
    background-color: var(--clueless-music-primary);
}

.home-tile-header.waterfall {
    background-color: var(--waterfall-primary);
}

.home-tile.clueless {
    border-color: var(--clueless-music-primary);
}

.home-tile.crossword {
    border-color: var(--crosswords-primary);
}

.home-tile.waterfall {
    border-color: var(--waterfall-primary);
}

.home-tile.chain {
    border-color: var(--chain-primary);
}

.home-tile-header.chain {
    background-color: var(--chain-primary);
}

.home-tile-header {
    width: 200px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile-specific styles */
@media screen and (max-width: 1100px) {
    .home-games {
        width: 100%;
    }

    .home-text {
        width: 80%;
    }

    .home-content {
        flex-direction: column;
    }

    .home-main-icon {
        width: 300px;
    }

    .home-description {
        margin-top: 5px;
    }

    .home-tile-button {
        width: 100%;
    }

    .home-tile {
        width: 100%;
    }

    .home-content .legal-info-item-container {
        position: relative;
        display: flex;
        align-items: center;
        align-self: center;
        padding: 0;
        font-size: .8em;
        left: 0;
        margin-bottom: 20px;
        gap: .2em;
        margin-top: .4em;
    }
}