.loading-v2 {
    position: fixed;
    display: flex;
    top: 3.6em;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    gap: 0;
}

.loading-v2-text {
    margin: 0;
    padding: 0;
    margin-top: -3em;
    color: var(--dark-text);
    padding-bottom: 10em;
}

.loading-v2-image {
    align-self: center;
    width: 50em;
    margin: 0;
    padding: 0;
}

@media (max-width: 1100px) {
    .loading-v2-image {
        width: 30em;
    }
}