@import '../../ColorConstants.css';

.hints-section .highlight {
    background-color: var(--crosswords-highlight);;
}

.hints {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1em;
    color: black;
}

.hints-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    align-self: flex-start;
    gap: 1em;
    margin-top: 1em;
}

.hint {
    margin: .2em 0;
    text-align: start;
    font-size: 1.2em;
}

.hint-section-header {
    font-size: 1.2em;
    text-align: start;
    margin: 0;
}