.game-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 1em;
    margin: 2em 0 0;
}

.game-button {
    width: 7em;
    padding: .5em .2em;
    font-size: 1.2em;
    text-align: center;
    border: none;
    border-radius: 1em;
    cursor: pointer;
}

.game-buttons-container.crossword button{
    width: auto;
    flex: 1;
    margin: 0;
}

@media (max-width: 1100px) {
    .game-button.keyboard {
        width: auto;
        font-size: 1em;
        flex: 1;
        padding: .6em;
        border: 0;
        cursor: none;
        transition: none;
        border-radius: 0;
    }

    .game-button.waterfall {
        width: 6em;
    }
}

.game-button.submit.clueless, .game-button.results.clueless {
    background: var(--cluess-primary);
    color: white;
}

.game-button.submit.crossword, .game-button.results.crossword {
    background: var(--crosswords-dark);
    color: var(--white);
}

.game-button.submit.chain, .game-button.results.chain {
    background: var(--chain-primary);
    color: var(--white);
}

.game-button.submit.waterfall, .game-button.results.waterfall {
    background: var(--waterfall-dark);
    color: var(--white);
}

.game-button.clear.waterfall {
    border: .1em solid var(--waterfall-dark);
    color: var(--waterfall-dark);
    background: white;
}

.game-button.give-up {
    background: grey;
    color: white;
}

.game-button.keyboard.crossword {
    border-top: .1em solid lightgray;
}