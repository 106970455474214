.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
}

.no-scroll {
    overflow: hidden;
}

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-container {
    background-color: var(--white);
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    overflow: scroll;
    padding: 0;
    margin: 0;
    position: relative;
}

.crossword-container {
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: row;
}

@media (max-width: 1100px) {
    .crossword-container {
        flex-direction: column;
    }
}