.legal-info-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.legal-info-popup {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    border-radius: .5em;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.legal-info-close {
    align-self: end;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: black
}

.legal-info-icon {
    width: 20em;
}

.legal-info-item {
    text-decoration: none;
    color: black;
}

.legal-info-all-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.legal-info-item-container {
    display: flex;
    flex-direction: row;
    margin-top: .6em;
    justify-content: space-between;
    gap: 1em;
    align-items: center;
    align-self: start;
}

.legal-info-p-item {
    padding: 0;
    margin: 0;
    margin-top: 1em;
    width: 100%;
}

.legal-info-item-dot {
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 900px) {
    .legal-info-popup {
        width: 90%;
    }
}

#legal-info-disclaimer {
    font-weight: bold;
}