/* src/css/crossword/Crossword.css */
@import '../../ColorConstants.css';

.crossword-game-content {
    display: flex;
    flex-direction: row;
    width: 90%;
    gap: 2em;
}

.crossword-grid {
    display: grid;
    justify-content: center;
    align-self: start;
    align-items: center;
    margin-top: 1em;
    grid-template-rows: repeat(20, 2.3vw);
}

.crossword-hint-grid {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.row {
    display: grid;
    grid-template-columns: repeat(20, 2.3vw);
}

.square {
    width: 2.3vw;
    height: 2.3vw;
    border: 1px solid #3e3e3e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4vw;
    position: relative;
    background-color: white;
}

.square-num {
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: .8vw;
    color: black;
}

.crosswords-submit-btn {
    background-color: var(--crosswords-primary);
    color: var(--black);
}

.square.red {
    background-color: #ff7d78;
}

.square.black {
    background-color: var(--black);
}

.square.yellow {
    background-color: #FDFF6C;
    color: black;
}

.square.green {
    background-color: #7ed698;
}

.square.blue {
    background-color: var(--crosswords-highlight);
}

.square.secondary {
    background-color: #2A9D8F;
}

.printing-answers-container {
    display: none;
}

/* Mobile-specific styles */
@media (max-width: 1100px) {
    .crossword-grid {
        width: 100%;
        grid-template-rows: repeat(20, 5vw);
        margin-top: 0;
    }

    .row {
        grid-template-columns: repeat(20, 5vw);
    }

    .square {
        width: 5vw;
        height: 5vw;
        font-size: 2.5vw;
    }

    .focused-word-container {
        width: 96%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
        margin-top: 5px;
        margin-left: 2%;
    }

    .focused-word-hint {
        text-align: center;
        font-size: 4vw;
        padding: 10px;
    }

    .mobile-grid-container {
        flex: 1;
    }
}
