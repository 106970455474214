.category-setup-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 3.5em;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.category-setup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white); /* White background */
    padding: 30px;
    border: 1px solid var(--black); /* Thick yellow border */
    border-radius: 10px;
    max-width: 800px;
    margin-bottom: 3.5em;
}

.category-setup-title {
    margin-bottom: .4em;
    margin-top: 0px;
    font-size: 1.6em;
    font-weight: bold;
    width: 100%;
    color: var(--dark-text);
    text-align: center;
}

.category-setup-popular-category {
    display: flex;
    background: var(--white);
    border: 2px solid var(--cluess-primary);
    color: var(--cluess-primary);
    font-weight: bold;
    padding: .5em;
    border-radius: 5em;
}

.category-setup-popular-category.crossword {
    border: 2px solid var(--crosswords-dark);
    color: var(--crosswords-dark);
}

.category-setup-popular-category-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 20em;
    gap: .5em;
    color: white;
}

.create-category-container {
    display: flex;
    flex-direction: column;
}

.create-category-input-container {
    display: flex;
    align-items: center;
    gap: 0; /* Optional: Adds some space between the input and the image */
    height: 2em; /* Ensure this matches the desired height */
    margin-bottom: 1.5em;
}

.create-category-input {
    flex: 1;
    height: 2em;
    border: .15em solid var(--cluess-primary);
    border-radius: 0.5em 0 0 0.5em;
    padding: .5em;
}

.create-category-input.crossword {
    border: .15em solid var(--crosswords-dark);
}

.create-category-input:focus {
    border: .15em solid var(--cluess-primary);
    outline: none;
}

.create-category-input.crossword:focus {
    border: .15em solid var(--crosswords-dark);
}

.category-setup-submit-button {
    height: 2.62em;
    width: 2.62em;
    background: var(--cluess-primary);
    border: 1px solid var(--cluess-primary);
    border-radius: 0 0.5em 0.5em 0;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.category-setup-submit-button.crossword {
    background: var(--crosswords-dark);
    border: 1px solid var(--crosswords-dark);
}

.category-setup-daily-button {
    background: var(--cluess-primary);
    padding: 1em;
    color: white;
    width: 100%;
    font-size: 1em;
    border: none;
    border-radius: 25px;
}

.category-setup-daily-button.crossword {
    background: var(--crosswords-dark);
}

.category-setup-daily {
    margin-top: .4em;
    margin-bottom: .8em;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}