/* Adjust keyboard container to ensure no overflow */
.keyboard-row {
    display: flex;
    width: 100%;
    height: 3em;
    justify-content: space-evenly;
    margin-bottom: 0.1em;
    gap: .1em;
    overflow: hidden;
}

.on-screen-keyboard-button {
    flex: 1;
    color: var(--dark-text);
    border: 0.07em solid #ccc;
    background: #fff;
}

.keyboard-backspace {
    padding: 1em;
    flex: 1.3;
    border: 1px solid #ccc;
    background: #fff;
}

.on-screen-keyboard {
    display: flex;
    bottom: 0;
    flex-direction: column;
    width: 100%;
    background: #f1f1f1;
    padding: 0;
    margin: 0;
    z-index: 500;
}

.keyboard-share-btn {
    height: 2.8em;
    width: auto;
}

.mobile-keyboard-arrow-toggle-container {
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.mobile-keyboard-arrow-button {
    width: auto;
    height: 1.5em;
}

.mobile-keyboard-submit-container {
    display: flex;
    flex-direction: row;
    width: 98%;
    gap: 1em;
    align-self: center;
    padding-top: .2em;
    padding-bottom: .2em;
    justify-content: center;
    align-items: center;
}

.mobile-keyboard-submit {
    width: 100%;
    height: 2em;
    border: none;
    font-size: 1.2em;
}

.submit-button-mobile:disabled {
    color: var(--disabled-grey);
    background: None;
}

.crosswords-buttons-container {
    margin: 0;
    padding: 0;
    width: 100%;
}

.crosswords-buttons {
    margin: 0;
}

.focused-word-hint {
    padding: .4em;
    font-size: 1em;
}

.keyboard-game-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
}
