/* src/css/crossword/Popup.css */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.share-url {
    display: inline-block;
    background: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: all;
    margin: 10px 0;
}

.popup-button {
    display: block;
    margin: 10px auto;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    width: 100%;
    transition: background-color 0.3s;
}

.popup-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: black
}

.popup-content h3{
    font-size: 1em;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 1em;
}

.popup-content.crossword .popup-button {
    background-color: var(--crosswords-primary);
}

.popup-content.crossword .popup-button:hover {
    background-color: var(--crosswords-dark);
}

.popup-content.crossword .popup-button.create-new {
    background-color: var(--white);
    border: 1px solid var(--crosswords-primary);
    color: var(--crosswords-primary);
}

.popup-content.crossword .popup-button.pick-new-category {
    background-color: var(--white);
    border: 1px solid var(--crosswords-primary);
    color: var(--crosswords-primary);
}

.popup-content.chain .popup-button.create-new {
    background-color: white;
    color: var(--chain-primary);
    border: 1px solid var(--chain-primary);
}

.popup-content.crossword .popup-button.more-games {
    background-color: var(--white);
    color: #a6a6a6;
    border: #a6a6a6 1px solid;
}

.popup-content.chain .popup-button {
    background-color: var(--chain-primary);
}

.popup-content.chain .popup-button:hover {
    background-color: var(--chain-third);
}

.popup-content.chain .popup-button.more-games {
    background-color: var(--white);
    color: var(--dark-text);
    border: var(--dark-text) 1px solid;
}

.popup-content.waterfall .popup-button.share {
    background-color: var(--waterfall-dark);
    color: white;
}

.popup-content.clueless .popup-button.share {
    background-color: var(--cluess-primary);
    color: white;
}

.popup-content.clueless .popup-button.create-new {
    background-color: white;
    border: 1px solid var(--cluess-primary);
    color: var(--cluess-primary);
}

.popup-content.clueless .popup-button.pick-new-category {
    background-color: white;
    border: 1px solid var(--cluess-primary);
    color: var(--cluess-primary);
}

.popup-content.waterfall .popup-button.create-new {
    background-color: white;
    color: var(--waterfall-dark);
    border: 1px solid var(--waterfall-dark);
}

.clipboard-alert {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--dark-text);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeInOut 2s forwards;
    z-index: 100000;
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

.results-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em;
}

.results-stats.crossword {
    justify-content: center;
}

.results-stats.clueless {
    justify-content: center;
}

.results-emoji-grid {
    white-space: pre-wrap;
    font-size: 1em;
}

.popup-results-title {
    font-size: 1.2em;
    padding: 0;
    margin: 0;
    margin-bottom: .2em;
}

.popup-more-ai-games-title {
    font-weight: normal;
    margin-top: .5em;
    font-size: .9em;
}

.puzzle-rating-container {

    display: flex;
    flex-direction: column;
    gap: 0em;
}

.puzzle-rating-container p {
    font-size: .7em;
    margin-top: .1em;
}

.puzzle-rating-text-and-input {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    margin-bottom: 0;
}

.puzzle-rating-text-and-input p {
    font-size: .8em;
    font-weight: bold;
    margin: 0;
}

.rating-stars {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.rating-star {
    cursor: pointer;
    margin: 0 5px;
    font-size: 1em; /* Adjust size as needed */
    color: #FFD700; /* Gold color for the stars */
}