.chain-words {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

@media (max-width: 1100px) {
    .chain-words {
        flex: 1;
    }
}

.chain-word {
    display: flex;
    gap: 5px;
}

.chain-letter {
    width: 50px;
    height: 50px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    background-color: #fff;
}

.chain-letter.highlighted {
    background-color: var(--chain-third);
}

.chain-letter.revealed {
    background-color: var(--chain-primary);
    color: white;
}

.chain-letter.victory {
    background-color: var(--chain-primary);
    color: white;
}

.chain-letter.given {
    background-color: var(--chain-primary);
    color: white;
}

.chain-letter.correct {
    background-color: var(--chain-primary);
    color: white;
}

.chain-letter.incorrect {
    background-color: var(--chain-incorrect);
    color: white;
}

.chain-letter.disabled {
    background-color: var(--silver);
    color: white;
}

.chain-arrow-btn {
    height: 40px;
    width: auto;
}

.chain-submit-button:disabled {
    color: var(--disabled-grey);
    border: 3px solid var(--disabled-grey);
}

.chain-submit-button {
    background: white;
    color: var(--chain-primary);
    border: 3px solid var(--chain-primary);
    border-radius: 20px;
    font-size: 1.2em;
    width: 10vw;
}

/* Mobile-specific styles */
@media (max-width: 1100px) {
    .chain-buttons {
        display: none;
    }

    .chain-letter {
        width: 2em;
        height: 2em;
    }

    .chain-word {
        width: 95vw;
        justify-content: center;
    }
}

@keyframes revealAnimation {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.incorrect {
    animation: revealAnimation 0.3s forwards;
}

.chain-letter.victory {
    animation: victory-animation 0.5s ease-in-out;
}

@keyframes victory-animation {
    0% { background-color: var(--chain-primary); }
    50% { background-color: #6cccf6; }
    100% { background-color: var(--chain-primary); }
}
