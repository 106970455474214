:root {
  --very-dark: #3D405B;
  --dark: #E07A5F;
  --primary: #81B29A;
  --light: #F2CC8F;
  --lightest: #F4F1DE;
  --white: #FFFFFF;
  --black: #000000;

  --waterfall-primary: #badae1;
  --waterfall-dark: #72a0b7;
  --waterfall-grey: #f0f0f0;
  --waterfall-tiles-used: #E9C46A;
  --crosswords-primary: #E9C46A;
  --crosswords-dark: #a3894c;
  --crosswords-light: #fff1b8;
  --crosswords-highlight: #69d0e2;
  --cluess-primary: #fe6a6b;
  --cluess-button-disabled: #ffccc3;
  --cluess-secondary: #9d4243;
  --clueless-movies-primary: #2A9D8F;
  --clueless-movies-light: #a9fffd;
  --clueless-music-primary: #e66f50;
  --chain-primary: #376579;
  --chain-secondary: #5196b4;
  --chain-third: #73d3fe;
  --chain-incorrect: #e66f50;
  --chain-victory: #e66f50;

  --home-icon-background: #7c7f7c;

  --primary-purple: #48207a;

  --dark-background: #1b1b1b;
  --dark-text: #1b1b1b;
  --silver: #7c7f7c;
  --disabled-grey: #7c7f7c;

  --secondary-text: #7b7b7b;
}